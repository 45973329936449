.button {
  font-weight: 400;
  font-size: 1.6rem;
  color: #252729;
  padding: 1.6rem 3.2rem;
  width: 19.4rem;
  height: 5.6rem;
  border: 3px solid #252729;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  font-family: "Outfit";
  background-color: #fff;
}

.button:hover {
  background: #f4f4f4;
}

.button:active {
  background: #252729;
  color: #ffffff;
}

.button:disabled {
  color: #a3a7ae;
  border: 3px solid #a3a7ae;
}

@media screen and (max-width: 768px) {
  .button {
    width: 100%;
    height: 6.6rem;
    font-size: 2rem;
  }
}
