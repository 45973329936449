/* @import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700&family=Roboto:wght@400;500;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700;800;900&family=Rajdhani:wght@400;500;600;700&family=Roboto:wght@400;500;700&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

:root {
  /* primary colors */
  --primary-color: #2087f5;
  --secondary-color: #edf6ff;

  --black-color: #000000;
  --white-color: #ffffff;
  --text-color: #252729;
  --gray-text-color: #;

  --placeholder-color: #a3a7ae;

  --title-size: 4.2rem;
  --body-size: 3.2rem;
  --subtitle-size: 2rem;
  --small-text-size: 1.6rem;
  --button-size: 2.4rem;

  --section-title-size: 5.6rem;

  /* alert colors */
  --alert-warning: #ffd20c;
  --alert-success: #528c28;
  --alert-error: #b7414b;

  --primary-font: "Outfit", sans-serif;
  --weight1: 700;
  --weight2: 600;
  --weight3: 500;
  --weight4: 400;
  --border-radius: 8px;
  --border-radius-large: 12px;
}

html {
  font-size: 62.5%;
  height: 100%;
  width: 100%;
}

html {
  /* font-size: 10px;  */
  /* 62.5% of 16px = 10px */
  line-height: 1.5;
}

body {
  z-index: 1;
  position: relative;
  overflow: scroll;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 100vh;
  max-width: 100vw;
  font-style: normal;
  letter-spacing: 0.03em;
  font-family: var(--primary-font);
  /* background-color: var(--background-page); */
  font-size: 16px;
}

/* custom classes */
.success {
  color: var(--alert-success);
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-column {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.flex-col-start {
  align-items: flex-start;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section__title {
  font-weight: 600;
  font-size: var(--section-title-size);
  text-transform: uppercase;
}

.logo {
  width: 17.5rem;
  height: 14.7rem;
}

.button {
  padding: 1.7rem 0px 1.7rem 1rem;
  background-color: var(--primary-color);
  color: var(--white-color);
  border-radius: var(--border-radius);
  font-weight: 400;
  font-size: 2.4rem;
  width: 30.5rem;
  height: 6.4rem;
  cursor: pointer;
}

.button:hover {
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.16);
}

.button:active {
  background: #1672d5;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.16);
}

.button-apple-icon {
  width: 3rem;
  margin-right: 2rem;
}

.section {
  width: 100%;
  min-height: 100%;
}

/* hero section */
.hero__container {
  background: linear-gradient(
      90deg,
      #ffffff 0.69%,
      rgba(255, 255, 255, 0.9) 54.48%,
      rgba(255, 255, 255, 0.6) 79.31%,
      rgba(255, 255, 255, 0.2) 100%
    ),
    url("./assets/images/dekstop-hero-bg.png");
  background-size: contain, cover;

  padding: 6rem 0px 0rem;
}

.section__width {
  margin: 0px auto;
  width: 80%;
}

.hero__logo-container {
  margin-bottom: 10rem;
}

.break-line {
  margin: 2.4rem 0px 0.6rem 0px;
  /* margin: 2.4rem 0px 0.6rem 0px;
  width: 40rem;
  height: 1px;
  background: #c6c6c8; */
}

.footer-break-line {
  margin: 2.4rem 0px 0.6rem 0px;
  width: 80%;
  height: 1px;
  background: #5f646c;
}

.hero__title {
  font-size: var(--title-size);
  font-weight: 900;
  text-transform: uppercase;
}

.hero__subtitle {
  font-size: var(--body-size);
  font-weight: 400;
}

.hero__note {
  font-weight: 200;
  font-size: var(--subtitle-size);
  margin: 2.4rem 0px 0.6rem 0px;
  /*
  width: 40rem;
  height: 1px;
  background: #c6c6c8;
  */
}

.hero__note span {
  padding-top: 0.6rem;
  border-top: 1px solid #c6c6c8;
}

.hero_button-container {
  margin-top: 3.2rem;
}

.hero-flex {
  align-items: flex-start;
}

.hero__left-container {
  flex: 70%;
  margin-top: 8rem;
  margin-left: 8%;
}

.hero__right-container {
  flex: 30%;
  align-self: flex-start;
  display: flex;
  justify-content: flex-end;
}

.hero-desktop-image {
  height: 75rem;
}

.hero-mobile-image {
  display: none;
}

.hero__phone-container {
  width: 100%;
  position: relative;
}

.iphone1,
.rightLine {
  position: absolute;
  right: 0px;
  left: 0px;
  /* top: -113px; */
  height: 70rem;
  z-index: 20;
}

.iphone2 {
  position: absolute;
  right: 0px;
  left: 14.6rem;
  top: -7.8rem;
  height: 70rem;
  z-index: 10;
}

.rightLine {
  width: 140%;
  height: 60rem;
  z-index: 1;
  left: 1rem;
}

/* feature section */
.feature__container {
  /* background: url("./assets/images/feature-background.png") #edf6ff; */
  background: #edf6ff;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: 5rem center;
  padding: 3rem 0px 6.4rem 0px;
  /* border-top: ; */

  border-width: 1px 0px;
  border-style: solid;
  border-color: #c6c6c8;
  /* z-index: 15; */
}

.feature__left-container {
  width: 100%;
  flex: 60%;
}

.feature__right-container {
  flex: 40%;
}

.feature__phone-group {
  height: auto;
  width: 95%;
  margin-top: -10rem;
  /* position: absolute;
  top: -33rem;
  right: 32rem;
  width: 75.9rem;
  height: 51.7rem; */
}

.feature-point-list {
  row-gap: 4rem;
}

.feature-item {
  display: flex;
  align-items: flex-start;
  column-gap: 1rem;
}

.check-icon {
  margin-top: 1rem;
}

.feature-item__title {
  font-weight: 600;
  /* font-size: var(--button-size); */
  /* font-size: var(--body-size); */
  font-size: 2.8rem;
  margin-bottom: 1.6rem;
  text-transform: uppercase;
}

.feature-item__subtitle {
  font-weight: 400;
  /* font-size: var(--small-text-size); */
  font-size: var(--subtitle-size);
  width: 33.6rem;
  /* margin-left: 5rem; */
}

/* .feature-button-container {
  margin-top: 6.4rem;
  display: flex;
  justify-content: center;
  margin-left: 67rem;
} */

.feature-button-container {
  column-gap: 0.8rem;
  margin-top: 4.4rem;
}

.hide-check-icon {
  opacity: 0;
}

.mobile-feature-button {
  display: none;
}

/* contact us section*/
.contact__container {
  padding: 4rem 12.2rem 6rem;
}

.contact__container > * {
  align-items: flex-start;
  column-gap: 4rem;
}

.contact__left-container {
  flex: 60%;
}

.contact__right-container {
  flex: 40%;
  padding-left: 10rem;
}

.contact__title {
  font-weight: 600;
  font-size: var(--body-size);
}

.contact-form {
  row-gap: 1.6rem;
  align-items: flex-start;
  margin-top: 2rem;
}

.contact-field-container {
  row-gap: 1.6rem;
  align-items: flex-start;
  width: 100%;
}

.contact-field-group {
  column-gap: 1rem;
  width: 100%;
}

.contact-logo {
  width: 105px;
  height: 88px;
  margin-bottom: 3.2rem;
}

.app-store {
  margin-bottom: 2.2rem;
}

.app-store img {
  width: 20rem;
}

.link,
.link:active,
.link:visited,
.link:active,
.link:hover {
  font-weight: 400;
  font-size: var(--small-text-size);
  text-decoration: none;
  margin-bottom: 1rem;
  color: #252729;
  font-family: "Roboto";
}

/* footer section */
.footer__container {
  background-color: #000000;
  height: 19.8rem;
  padding: 4rem 0px 1.6rem 0px;
}

.footer__text,
.footer__text-company {
  font-weight: 400;
  font-size: var(--small-text-size);
  color: var(--white-color);
  font-family: "Roboto", sans-serif;
}

.footer__text-company {
  font-family: "Rajdhani", sans-serif;
  font-size: var(--subtitle-size);
  cursor: pointer;
}

.footer-logo {
  height: 2rem;
  margin-bottom: -0.4rem;
}

/*  */

.w-700 {
  font-weight: var(--weight1);
}

.w-600 {
  font-weight: var(--weight2);
}

.w-500 {
  font-weight: var(--weight3);
}

.w-400 {
  font-weight: var(--weight4);
}

/* error message */
.errorMessage {
  color: var(--alert-error);
  margin-top: 5px;
  font-size: 1.5rem;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.center {
  text-align: center;
}

/* scroll bar styles */
/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media screen and (min-width: 1500px) {
  .check-icon {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 2507px) {
  .feature__container {
    background-size: 44%;
  }

  /* .feature-button-container {
    display: flex;
    justify-content: center;
    margin-left: 49%;
    margin-top: 6.4rem;
  } */
}

@media screen and (max-width: 1300px) {
  html {
    font-size: 44.5%;
  }

  .break-line {
    /* width: 63%; */
    width: 46%;
  }

  .check-icon {
    margin: 0px;
  }

  /* .feature-button-container {
    margin-top: 6.4rem;
    display: flex;
    justify-content: center;
    margin-left: 44%;
  } */

  .feature__phone-group {
    height: auto;
    width: 86%;
    margin-top: -10rem;
  }
}

@media screen and (max-width: 1024px) {
  .footer__container {
    background-position: left center;
  }

  .feature__phone-group {
    height: auto;
    width: 93%;
    /* margin-top: 8rem; */
  }
}

@media screen and (max-width: 768px) {
  section {
    width: 100%;
    /* min-height: 140vh !important; */
  }

  .flex {
    flex-direction: column;
  }

  .flex-reverse {
    flex-direction: column-reverse;
  }

  .section__width {
    width: 100%;
    padding: 0px 1.6rem;
  }

  .button {
    padding: 1.7rem 1rem 1.7rem 2rem;
    background-color: var(--primary-color);
    color: var(--white-color);
    border-radius: var(--border-radius);
    font-weight: 400;
    font-size: 3.4rem;
    width: 39.5rem;
    height: 7.4rem;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .button-apple-icon {
    width: 3.5rem;
    margin-right: 2rem;
  }

  /* hero */
  .hero__container {
    background: url("./assets/images/hero-bg.png");
    background-size: 100%, 100%;
    background-repeat: no-repeat;
    padding: 5rem 0px 0px;
  }

  .hero__logo-container {
    display: flex;
    justify-content: center;
  }

  .hero__left-container {
    flex: 70% 1;
    margin-top: 0rem;
    margin-left: 0;
    margin-bottom: 1rem;
  }

  .hero__title {
    font-size: 3.4rem;
    text-align: center;
  }

  .hero__subtitle {
    font-size: 2.4rem;
    text-align: center;
  }

  .hero__note {
    text-align: center;
  }

  .break-line {
    display: flex;
    justify-content: center;
    width: 89%;
    margin: 1.6rem auto 0.3rem;
  }

  .hero_button-container {
    display: flex;
    justify-content: center;
  }

  .hero__right-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .hero-desktop-image {
    display: none;
  }

  .hero-mobile-image {
    display: block;
    height: auto;
    width: 50rem;
  }

  .iphone1 {
    position: absolute;
    /* right: 0px; */
    left: 43rem;
    top: 13rem;
    height: 57rem;
    z-index: 20;
    transform: matrix(0.98, 0.21, -0.21, 0.98, 0, 0);
  }

  .iphone2 {
    position: absolute;
    /* right: 20rem; */
    left: 63rem;
    top: 4.2rem;
    height: 52rem;
    z-index: 10;
    transform: matrix(0.98, 0.21, -0.21, 0.98, 0, 0);
  }

  .rightLine {
    width: auto;
    height: 55rem;
    z-index: 1;
    left: 44rem;
    top: 10rem;
  }

  /* feature section */
  .feature__container {
    /* background-position: left bottom; */
    background-position: center 73rem;
    background-size: 82%;
    padding: 1.6rem 0px 6.4rem 0px;
  }

  .feature__right-container {
    margin-left: 10rem;
    margin: 0px auto;
    width: 52%;
    margin-bottom: 5rem;
  }

  .feature__phone-group {
    margin-top: 0rem;
    width: 78%;
  }

  /* .feature__phone-group {
    position: absolute;
    top: 63rem;
    right: 26rem;
    width: 75.9rem;
    height: 51.7rem;
  } */

  .feature-point-list {
    row-gap: 2rem;
  }

  .desktop-feature-button {
    display: none !important;
  }

  .mobile-feature-button {
    display: block;
  }

  .feature-button-container {
    margin-left: 0rem;
    display: flex;
    justify-content: center;
  }

  /* contact section*/
  .contact__container {
    padding: 3rem 1.6rem;
  }

  .contact__container > * {
    flex-direction: column;
  }

  .contact__left-container {
    width: 100%;
  }

  .contact__right-container {
    flex: 40% 1;
    margin-top: 5rem;
    padding-left: 0rem;
  }

  .contact__title {
    text-align: center;
  }

  .contact-form {
    row-gap: 2.5rem;
  }

  .contact-field-container {
    row-gap: 2rem;
    align-items: flex-start;
    width: 100%;
  }

  .contact-field-group {
    row-gap: 2rem;
  }
}

@media screen and (max-width: 500px) {
  .hero__logo-container {
    margin-bottom: 7rem;
  }

  .feature__container {
    /* background-position: left bottom; */

    background-size: 92%;
  }

  .feature__right-container {
    margin-left: 10rem;
    margin: 0px auto;
    width: 80%;
    margin-bottom: 5rem;
  }

  .feature__phone-group {
    width: 92%;
  }
}
