.main {
  width: 100%;
}

.inputText {
  padding: 1.6rem 1.2rem;
  border: 1px solid #c6c6c8;
  border-radius: 8px;
  width: 100%;
}

.inputText::placeholder {
  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: 0.4px;
  color: #a3a7ae;
  font-family: "Outfit";
}

/* textarea tag style */
textarea.inputText {
  /* color: var(--text-regular); */
  font-size: 1.8rem;
  height: 10rem;
  padding: 2rem;
  width: 100%;
}

textarea.inputText::placeholder {
  font-weight: 400;
  font-size: 1.6rem;
  color: #a3a7ae;
  letter-spacing: 0.4px;
  font-family: "Outfit";
}

/* error and success styles */
.error.inputText,
.error.selectBtn {
  border-color: var(--alert-error);
}

@media screen and (max-width: 768px) {
  .inputText {
    padding: 2rem 1.5rem;
  }

  .inputText::placeholder {
    font-weight: 400;
    font-size: 2rem;
    letter-spacing: 0.4px;
    color: #a3a7ae;
    font-family: "Outfit";
  }

  textarea.inputText::placeholder {
    font-weight: 400;
    font-size: 2rem;
    color: #a3a7ae;
    letter-spacing: 0.4px;
    font-family: "Outfit";
  }
}
